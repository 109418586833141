@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import "~antd/dist/antd.less";
// @import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

::selection {
  color: #fff;
  // background: #373d41;
  background: #161f31;
}
.pointer tbody tr {
  cursor: pointer;
}
.first-capitalize::first-letter {
  text-transform: uppercase;
}
.selected-row,
.selected-row:hover {
  background: #ab307e;
  color: #fff;
  .ant-table-cell.ant-table-cell-row-hover {
    background: #832561;
    color: #fff;
  }
}
.header {
  display: flex;
  align-items: center;
  .logo {
    font-weight: 500;
    color: #fff;
    margin: 0;
    padding: 0;
    flex-grow: 1;
  }
}
.content-body {
  padding: 24px;
  margin: 0;
}
.body-height {
  height: calc(100vh - 64px);
}
.disable-select {
  user-select: none;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-auto {
  overflow-x: auto;
  overflow-y: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}
.capitalize {
  text-transform: capitalize;
}

.ant-form-item-explain-error {
  font-size: small;
}

.ant-table-body,
.appscrollbar {
  &::-webkit-scrollbar {
    width: 0.7em;
  }
  &::-webkit-scrollbar-track {
    background: #e5e5e5;
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c9c9c9;
    outline: none;
  }
}
.ant-modal-header {
  background: #f9f9f9;
}

.rdw-dropdown-optionwrapper {
  .appscrollbar;
  &::-webkit-scrollbar {
    width: 0.4em;
  }
}
.editor-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  overflow: hidden;
  .rdw-editor-toolbar {
    border: 0;
    margin-bottom: 0;
    padding: 0;
    padding-top: 5px;
    border-bottom: 1px solid #d9d9d9;
    .rdw-dropdown-wrapper {
      .rdw-dropdown-selectedtext {
        color: inherit;
      }
      border: 0;
      &:hover {
        background: #f5f5f5;
        box-shadow: none;
      }
    }
  }
  margin-bottom: 20px;
}
.demo-editor {
  padding: 0 15px;
}

.progress-index-card {
  background: white;
  overflow: hidden;
  user-select: none;
  padding: 10px;

  border-left: 3px solid transparent;
  border-bottom: 2px solid #eaeaea;
  &:hover {
    cursor: pointer;
  }
}
.progress-index-card.selected {
  border-left: 3px solid #1e40af;
  background: #f9f9f9;
}
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@body-background: #f8fafc;@primary-color: #1e40af;@font-family: Roboto, sans-serif;@text-color: #334155;@layout-header-background: #334155;