@media print {
  body {
    background: white;
  }
  .ant-layout.content-body {
    background: white;
    margin: 0;
  }
  header.ant-layout-header.header {
    display: none;
  }
  aside.ant-layout-sider {
    display: none;
  }
  .combo-print-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .text-right {
      text-align: right;
    }
    .text-left {
      text-align: left;
    }
    .overall-macro {
      border: 2px solid rgb(82, 19, 116);
      border-radius: 5px;
      width: 90%;
    }
    table.overall {
      width: 100%;
      td {
        padding: 10px;
      }
    }
    .dine-wrapper {
      page-break-inside: avoid;
      width: 100%;
    }
    .dine-title {
      margin-top: 20px;
      text-align: center;
      print-color-adjust: exact;
      background: rgb(82, 19, 116) !important;
      color: white;
      padding: 10px;
      border-radius: 5px;
    }
    table.dine {
      width: 100%;
      td,
      th {
        padding: 10px;
        vertical-align: top;
      }
    }
    .macro {
      width: 100%;
      display: flex;
      padding: 10px;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      border-top: 2px solid rgb(82, 19, 116);
    }
  }
}

@media screen {
  .combo-print-wrapper {
    display: none;
    // display: flex;
    flex-direction: column;
    align-items: center;
    .text-right {
      text-align: right;
    }
    .text-left {
      text-align: left;
    }
    .overall-macro {
      border: 2px solid rgb(82, 19, 116);
      border-radius: 5px;
      width: 90%;
    }
    table.overall {
      width: 100%;
      td {
        padding: 10px;
      }
    }
    .dine-wrapper {
      width: 90%;
    }
    .dine-title {
      margin-top: 20px;
      text-align: center;
      background: rgb(82, 19, 116) !important;
      color: white;
      padding: 10px;
      border-radius: 5px;
    }
    table.dine {
      width: 90%;
      td,
      th {
        padding: 10px;
        vertical-align: top;
      }
    }
    .macro {
      width: 100%;
      display: flex;
      padding: 10px;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      border-top: 2px solid rgb(82, 19, 116);
    }
  }
}

@body-background: #f8fafc;@primary-color: #1e40af;@font-family: Roboto, sans-serif;@text-color: #334155;@layout-header-background: #334155;